<template>
  <div>
    <label class="block" v-if="label" :for="id"
      >{{ label }} <span v-if="required" class="text-red-500">*</span></label
    >
    <validation-provider :rules="rules" v-slot="{ errors }">
      <div class="select-wrapper">
        <select
          ref="input"
          class="rounded px-4 focus:outline-none border border-gray-border w-full"
          :id="id"
          @change="updateValue"
        >
          <option v-for="(option, i) in options" :disabled="option.disabled" :selected="option.selected" :key="i" :value="option.value">
            {{ option.name }}
          </option>
        </select>
      </div>
      <div class="pt-1" v-if="errors.length">
        <small
          class="text-red-500 text-sm"
          v-for="(error, index) in errors"
          :key="index"
          >{{ error }}</small
        >
      </div>
    </validation-provider>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    rules: {
      type: String
    },
    label: {
      type: String,
    },
    value: {
      type: null,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    updateValue() {
      this.$emit("input", this.$refs.input.value);
    },
  },
};
</script>

<style scoped lang="scss">
select {
  height: 42px;
  margin-top: 6px;
}
</style>