<template>
  <div>
    <label class="block" v-if="label" :for="id">{{ label }} <span v-if="required" class="text-red-500">*</span></label>
    <div class="selectopt-wrapper">
      <select
        ref="input"
        class="rounded px-4 focus:outline-none border border-gray-border w-full"
        :id="id"
        @change="updateValue()"
      >
        <optgroup v-for="(option, i) in options" :key="i" :label="i">
          <option v-for="(sub, i) in option" :key="i" :value="sub.value">
            {{ sub.name }}
          </option>
        </optgroup>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectOptGroup",
  props: {
    options: {
      type: Object,
      required: true,
    },
    label: String,
    id: String,
    required: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    updateValue() {
      this.$emit("input", this.$refs.input.value);
    },
  },
};
</script>

<style scoped lang="scss">
.selectopt-wrapper {
  margin-top: 6px;
}

select {
  height: 42px;
}
</style>