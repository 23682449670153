<template>
  <div class="bg-gray-main py-16 mb-16">
    <ValidationObserver v-slot="{ invalid }" ref="companyForm">
      <form @submit.prevent="submitCompanyUpdate">
        <div class="container">
          <header class="mb-4">
            <h2 class="text-3xl font-bold">Add Company</h2>
            <p>Add company details.....</p>
          </header>
          <div class="mt-16">
            <div class="shadow p-8 bg-white mt-8">
              <h4 class="text-xl font-bold">Company Information</h4>
              <div class="grid md:grid-cols-2 gap-6 mt-6">
                <div>
                  <TextBox
                    id="companyName"
                    type="text"
                    label="Name"
                    rules="required"
                    v-model="companyUpdateForm.name"
                  />
                </div>
                <div>
                  <ImageUpload
                    @input="handlePhotoUpload"
                    :value="companyUpdateForm.logoPaths"
                  />
                </div>
              </div>
            </div>

            <div class="mt-16 flex items-center justify-center">
              <Button
                :loading="formSubmitting"
                type="submit"
                :disabled="invalid"
                text="Add Company"
              />
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { http } from "@/helpers/http";
import Select from "@/components/core/Select";
import Button from "@/components/core/Button";
import TextBox from "@/components/core/TextBox";
import MessagePrompt from "@/components/core/MessagePrompt";
import SelectOptGroup from "@/components/core/SelectOptGroup";
import { getJobFamilies, getGender, getLevels } from "@/helpers/enums";

import ImageUpload from "@/components/core/ImageUpload";
import { uploadAsset } from "@/helpers/firebase";

export default {
  name: "AddCompanyUpdate",
  components: { TextBox, Select, Button, SelectOptGroup, MessagePrompt, ImageUpload },
  mounted() {
    this.jobsOptions = getJobFamilies();
    this.genderOptions = getGender();
    this.levelsOptions = getLevels();
  },
  data() {
    return {
      companyId: 2319, // get from $route
      companyUpdateForm: {
        name: null,
        logoPaths: null,
      },

      formSubmitting: false,
    };
  },
  methods: {
    submitCompanyUpdate() {},

    async handlePhotoUpload(files) {
      console.log(files);
      let photoPaths = "";
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let url;
        if (file instanceof File) {
          try {
            url = await uploadAsset(file);
          } catch (e) {
            console.log(e);
          }
        } else url = file; // file is "null"
        if (photoPaths && photoPaths.length) photoPaths += "|" + url;
        else photoPaths = url;
      }

      console.log(photoPaths);
      this.companyUpdateForm.logoPaths = photoPaths;
    },
  },
};
</script>

<style>
</style>